
const colors = {
  background: '#C68D96',
  charcoal: '#262626',
  emerald800: '#060f0E',
  emerald700: '#081311',
  emerald600: '#0A1815',
  emerald500: '#0C1E1A',
  emerald400: '#0F2521',
  emerald300: '#3F514D',
  emerald200: '#657471',
  emerald100: '#84908D',
  emerald50: '#9DA6A4',
  mauve800: '#4B3B42',
  mauve700: '#5E4A52',
  mauve600: '#765D66',
  mauve500: '#93747F',
  mauve400: '#B8919F',
  mauve300: '#C6A7B2',
  mauve200: '#D1B9C1',
  mauve100: '#DAC7CD',
  mauve50: '#E1D2D7',
  opal800: '#414946',
  opal700: '#515B58',
  opal600: '#65726E',
  opal500: '#7E8E8A',
  opal400: '#9DB2AD',
  opal300: '#B1C1BD',
  opal200: '#C1CDCA',
  opal100: '#CDD7D5',
  opal50: '#D7DFDD',
  pink800: '#65555B',
  pink700: '#7E6A72',
  pink600: '#9E848E',
  pink500: '#C6A5B1',
  pink400: '#F7CEDD',
  pink300: '#F9D8E4',
  pink200: '#FAE0E9',
  pink100: '#FBE6ED',
  pink50: '#FCEBF1',
  roseIvory: '#F9F2F7'
}

export default colors
